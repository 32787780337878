import { FaDiscord } from "@react-icons/all-files/fa/FaDiscord";
import { memo } from 'react';
import { Link } from 'react-router-dom';
import Url from "../utils/Url";
import { authColors } from './auth/AuthStyles';
import { useTheme } from "./ThemeContext";

const Footer = () => {
    const { isDarkMode, theme } = useTheme();

    const footerStyles = {
        background: isDarkMode 
            ? authColors.dark.background
            : authColors.light.background,
        backdropFilter: 'blur(10px)',
        borderTop: `1px solid ${isDarkMode 
            ? authColors.dark.border
            : authColors.light.border}`,
        boxShadow: `0 -4px 6px -1px ${isDarkMode 
            ? 'rgba(134, 194, 50, 0.2)' 
            : 'rgba(13, 110, 253, 0.2)'}`,
    };

    const linkStyles = {
        color: isDarkMode ? authColors.dark.primary : authColors.light.primary,
        transition: 'none',
    };

    return (
        <footer className={`w-full py-6 ${theme.cardBg}`} style={footerStyles}>
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="flex flex-col items-center gap-4">
                    <div className="flex flex-wrap justify-center gap-4 text-center">
                        <p className={`${theme.text} font-medium`}>
                            &copy; {new Date().getFullYear()} Revise Wizard. All rights reserved.
                        </p>
                        <Link to={Url.USER_AGREEMENT}
                              style={linkStyles}
                              className="hover:opacity-80">
                            User Agreement
                        </Link>
                        <Link to={Url.TERMS_OF_SERVICE}
                              style={linkStyles}
                              className="hover:opacity-80">
                            Terms of Service
                        </Link>
                        <Link to={Url.PRIVACY_POLICY}
                              style={linkStyles}
                              className="hover:opacity-80">
                            Privacy Policy
                        </Link>
                    </div>
                    <a
                        href="https://discord.gg/PCz8ET778U"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 px-4 py-2 text-white transition-all rounded-lg bg-[#5865F2] hover:bg-[#4752C4] hover:scale-105"
                    >
                        <FaDiscord className="w-5 h-5" />
                        <span>Join our Discord</span>
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default memo(Footer);