import axios from 'axios';
import { motion } from 'framer-motion';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { MultiValue } from 'react-select';
import { useTheme } from "../../components/ThemeContext";
import { useFooter } from '../../contexts/FooterContext';
import { AcademicLevel } from "../../utils/AcademicLevel";
import { ExamPaperBuildAPIUrls } from "../../utils/APIUrls";
import { getCookie, removeCookie } from '../../utils/Cookies';
import { HTTPErrorCode } from '../../utils/HTTPCode';
import Url, { getWebsiteUrl } from "../../utils/Url";

interface TopicOption {
    value: string;
    label: string;
}

interface Question {
    questionIds: string[];
    questionName: string[];
    previewUrls: string[];
}

interface ZoomState {
    [questionId: string]: {
        scale: number;
        fitScale: number;
    };
}

const levelLabels: Record<AcademicLevel, string> = {
    [AcademicLevel.GCSE]: 'GCSE',
    [AcademicLevel.AS_LEVEL]: 'AS Level',
    [AcademicLevel.A_LEVEL]: 'A Level'
};

const ExamPaperBuilder: React.FC = () => {
    const { isDarkMode, theme } = useTheme();
    const navigate = useNavigate();
    const { setShowFooter } = useFooter();

    const defaultLevel: AcademicLevel = AcademicLevel.A_LEVEL;

    const [selectedLevel, setSelectedLevel] = useState<AcademicLevel>(() => {
        const savedLevel = localStorage.getItem('selectedLevel');
        return savedLevel ? JSON.parse(savedLevel) : defaultLevel;
    });
    const [topics, setTopics] = useState<TopicOption[]>([]);
    const [selectedTopics, setSelectedTopics] = useState<MultiValue<TopicOption>>(() => {
        const savedTopics = localStorage.getItem('selectedTopics');
        return savedTopics ? JSON.parse(savedTopics) : [];
    });
    const [questions, setQuestions] = useState<Question | null>(() => {
        const savedQuestions = localStorage.getItem('questions');
        return savedQuestions ? JSON.parse(savedQuestions) : null;
    });
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number | null>(() => {
        const savedIndex = localStorage.getItem('selectedQuestionIndex');
        return savedIndex ? JSON.parse(savedIndex) : null;
    });
    const [basket, setBasket] = useState<string[]>(() => {
        const savedBasket = localStorage.getItem('basket');
        return savedBasket ? JSON.parse(savedBasket) : [];
    });
    const [isFetchingQuestions, setIsFetchingQuestions] = useState<boolean>(false);
    const [isTopicSelectionDisabled, setIsTopicSelectionDisabled] = useState<boolean>(() => {
        const savedDisabled = localStorage.getItem('isTopicSelectionDisabled');
        return savedDisabled ? JSON.parse(savedDisabled) : false;
    });
    const [isContinueDisabled, setIsContinueDisabled] = useState<boolean>(() => {
        const savedDisabled = localStorage.getItem('isContinueDisabled');
        return savedDisabled ? JSON.parse(savedDisabled) : false;
    });
    const [isResetDisabled, setIsResetDisabled] = useState<boolean>(() => {
        const savedDisabled = localStorage.getItem('isResetDisabled');
        return savedDisabled ? JSON.parse(savedDisabled) : true;
    });

    const [searchQuery, setSearchQuery] = useState('');
    const [isBuildingPaper, setIsBuildingPaper] = useState(false);
    const [showBasketPreview, setShowBasketPreview] = useState(false);
    const [zoomStates, setZoomStates] = useState<ZoomState>({});
    const imageRef = useRef<HTMLImageElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTopics, setLoadingTopics] = useState<boolean>(false);

    const handleError = useCallback((error: any) => {
        if (error.response) {
            switch (error.response.status) {
                case HTTPErrorCode.UNAUTHORIZED:
                case HTTPErrorCode.FORBIDDEN:
                    alert('Authentication error. Please log in again.');
                    removeCookie('jwt');
                    navigate('/');
                    break;
                case HTTPErrorCode.NOT_FOUND:
                    alert('The requested resource was not found.');
                    break;
                case HTTPErrorCode.TOO_MANY_REQUESTS:
                    alert('Too many requests. Please try again later.');
                    break;
                case HTTPErrorCode.INTERNAL_SERVER_ERROR:
                    alert('Server error. Please try again later.');
                    break;
                default:
                    alert('An unexpected error occurred. Please try again.');
            }
        } else if (error.request) {
            alert('Network error. Please check your connection.');
        } else {
            alert('An unexpected error occurred');
        }
    }, [navigate]);

    const fetchTopics = useCallback(async (level: string) => {
        setLoadingTopics(true);
        try {
            const params = new URLSearchParams();
            params.append('level', level);

            const response = await axios.get(ExamPaperBuildAPIUrls.TOPICS_FOR_LEVEL, { params });
            const fetchedTopics = Object.keys(response.data).map(topic => ({ value: topic, label: topic }));
            setTopics(fetchedTopics);
            localStorage.setItem('topics', JSON.stringify(fetchedTopics));
        } catch (error) {
            console.error('Error fetching topics:', error);
            handleError(error);
        } finally {
            setLoadingTopics(false);
        }
    }, [handleError]);

    const fetchQuestions = useCallback(async () => {
        setLoading(true);
        try {
            const topicsAndLevels = selectedTopics.reduce((acc: Record<string, string>, topic) => {
                acc[topic.value] = selectedLevel.toLowerCase();
                return acc;
            }, {});

            const response = await axios.post(
               ExamPaperBuildAPIUrls.QUESTIONS_FOR_TOPICS,
                topicsAndLevels,
                {
                    headers: {
                        'Authorization': `Bearer ${getCookie('jwt')}`
                    }
                }
            );

            let { questionName, questionIds, previewUrls } = response.data;

            const sortedQuestions = questionName
                .map((name: string, index: number) => ({
                    name,
                    id: questionIds[index],
                    url: previewUrls[index]
                }))
                .sort((a: { name: { match: (arg0: RegExp) => any[]; }; }, b: { name: { match: (arg0: RegExp) => any[]; }; }) => {
                    const aNumber = parseInt(a.name.match(/\d+/)?.[0] || '0', 10);
                    const bNumber = parseInt(b.name.match(/\d+/)?.[0] || '0', 10);
                    return aNumber - bNumber;
                });

            questionName = sortedQuestions.map((q: { name: any; }) => q.name);
            questionIds = sortedQuestions.map((q: { id: any; }) => q.id);
            previewUrls = sortedQuestions.map((q: { url: any; }) => q.url);

            const fetchedQuestions: Question = {
                questionIds,
                questionName,
                previewUrls
            };

            setQuestions(fetchedQuestions);
            localStorage.setItem('questions', JSON.stringify(fetchedQuestions));
        } catch (error) {
            console.error('Error fetching questions:', error);
            handleError(error);
        } finally {
            setLoading(false);
            setIsFetchingQuestions(false);
        }
    }, [selectedTopics, selectedLevel, handleError]);


    useEffect(() => {
        if (selectedLevel) {
            fetchTopics(selectedLevel).then(r => r);
        }
    }, [selectedLevel, fetchTopics]);

    useEffect(() => {
        if (selectedTopics.length > 0 && isFetchingQuestions) {
            fetchQuestions().then(r => r);
        }
    }, [selectedTopics, isFetchingQuestions, fetchQuestions]);

    useEffect(() => {
        localStorage.setItem('selectedLevel', JSON.stringify(selectedLevel));
        localStorage.setItem('selectedTopics', JSON.stringify(selectedTopics));
        localStorage.setItem('selectedQuestionIndex', JSON.stringify(selectedQuestionIndex));
        localStorage.setItem('basket', JSON.stringify(basket));
        localStorage.setItem('isTopicSelectionDisabled', JSON.stringify(isTopicSelectionDisabled));
        localStorage.setItem('isContinueDisabled', JSON.stringify(isContinueDisabled));
        localStorage.setItem('isResetDisabled', JSON.stringify(isResetDisabled));
    }, [selectedLevel, selectedTopics, selectedQuestionIndex, basket, isTopicSelectionDisabled, isContinueDisabled, isResetDisabled]);

    useEffect(() => {
        setShowFooter(false);
        return () => setShowFooter(true);
    }, [setShowFooter]);

    const handleLevelChange = (level: 'gcse' | 'aslevel' | 'alevel') => {
        let academicLevel: AcademicLevel;
        switch (level) {
            case 'gcse':
                return;
            case 'aslevel':
                academicLevel = AcademicLevel.AS_LEVEL;
                break;
            case 'alevel':
                academicLevel = AcademicLevel.A_LEVEL;
                break;
        }
        setSelectedLevel(academicLevel);
        setSelectedTopics([]);
        setQuestions(null);
        setSelectedQuestionIndex(null);
        setBasket([]);
        setIsTopicSelectionDisabled(false);
        setIsContinueDisabled(false);
        setIsResetDisabled(true);
    };

    const handleTopicChange = (topic: TopicOption) => {
        setSelectedTopics(prev => {
            const isTopicSelected = prev.some(t => t.value === topic.value);
            if (isTopicSelected) {
                return prev.filter(t => t.value !== topic.value);
            } else {
                return [...prev, topic];
            }
        });
        setIsResetDisabled(false);
    };

    const handleQuestionSelect = (index: number) => {
        setSelectedQuestionIndex(index);
    };

    useEffect(() => {
        console.log('Basket updated:', basket);
    }, [basket]);

    const handleRemoveFromBasket = (questionId: string) => {
        setBasket(basket.filter(id => id !== questionId));
    };

    const handleBuildExamPaper = async () => {
        setIsBuildingPaper(true);
        try {
            const response = await axios.post(ExamPaperBuildAPIUrls.BUILD_EXAM_PAPER, basket, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getCookie('jwt')}`
                },
                responseType: 'arraybuffer'
            });

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'exam_paper.pdf';
            link.click();
        } catch (error) {
            console.error('Error building exam paper:', error);
            handleError(error);
        } finally {
            setIsBuildingPaper(false);
        }
    };

    const handleContinue = () => {
        if (selectedTopics.length > 0) {
            setIsFetchingQuestions(true);
            setIsTopicSelectionDisabled(true);
            setIsContinueDisabled(true);
            setIsResetDisabled(false);
        }
    };

    const handleClear = () => {
        setSelectedTopics([]);
        setQuestions(null);
        setSelectedQuestionIndex(null);
        setBasket([]);
        setIsTopicSelectionDisabled(false);
        setIsContinueDisabled(false);
        setIsResetDisabled(true);
    };

    const handleReset = () => {
        handleClear();
        setIsResetDisabled(true);
        localStorage.clear();
    };

    const calculateFitScale = (imgWidth: number, imgHeight: number, containerWidth: number, containerHeight: number) => {
        const widthRatio = containerWidth / imgWidth;
        const heightRatio = containerHeight / imgHeight;
        return Math.min(widthRatio, heightRatio, 1); // Never zoom in past 100%
    };

    const handleImageLoad = (questionId: string) => {
        if (imageRef.current && containerRef.current) {
            const fitScale = calculateFitScale(
                imageRef.current.naturalWidth,
                imageRef.current.naturalHeight,
                containerRef.current.clientWidth,
                containerRef.current.clientHeight
            );
            
            setZoomStates(prev => ({
                ...prev,
                [questionId]: {
                    scale: fitScale,
                    fitScale
                }
            }));
        }
    };

    const handleZoomChange = (questionId: string, newScale: number) => {
        setZoomStates(prev => ({
            ...prev,
            [questionId]: {
                ...prev[questionId],
                scale: Math.min(Math.max(0.1, newScale), 2) // Limit zoom between 10% and 200%
            }
        }));
    };

    const handleZoomToFit = (questionId: string) => {
        if (zoomStates[questionId]) {
            setZoomStates(prev => ({
                ...prev,
                [questionId]: {
                    ...prev[questionId],
                    scale: prev[questionId].fitScale
                }
            }));
        }
    };

    const filteredQuestions = useMemo(() => {
        if (!questions) return [];
        return questions.questionIds
            .map((id, index) => ({
                id,
                name: questions.questionName[index],
                previewUrl: questions.previewUrls[index]
            }))
            .filter(q => q.name.toLowerCase().includes(searchQuery.toLowerCase()));
    }, [questions, searchQuery]);

    const customStyles = {
        container: `min-h-screen ${theme.background}`,
        card: `p-6 ${theme.cardBg} shadow-lg rounded-2xl backdrop-blur-sm bg-opacity-90 hover:shadow-xl transition-all duration-300`,
        heading: `text-2xl font-bold ${theme.text}`,
        text: theme.text,
        button: `px-4 py-2 font-semibold text-white transition-all duration-300 rounded-lg 
                bg-gradient-to-r from-green-600 to-green-500 
                hover:from-green-700 hover:to-green-600 
                focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50`,
        input: `block w-full px-3 py-2 mt-1 border rounded-md shadow-sm 
                ${isDarkMode 
                    ? 'bg-gray-700 border-gray-600 text-white focus:border-green-500' 
                    : 'border-gray-300 focus:border-green-500'
                } focus:outline-none focus:ring-green-500`
    };

    return (
        <div className={`min-h-screen ${theme.background}`}>
            <Helmet>
                <title>Revise Wizard - Exam Paper Builder</title>
                <meta name="description" content="Build custom exam papers with Revise Wizard's Exam Paper Builder." />
                <link rel="canonical" href={getWebsiteUrl() + Url.MATH_EXAM_PAPER_BUILDER}/>
            </Helmet>
            <div className="flex h-screen overflow-hidden md:flex-row bg-gradient-to-br from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-900">
                {/* Left Sidebar - Topics */}
                <div className="flex flex-col w-72 min-w-[18rem] h-full overflow-hidden bg-white/90 backdrop-blur-md dark:bg-gray-800/90 dark:border-gray-700">
                    {/* Header Section */}
                    <div className="flex-shrink-0 p-4 border-b border-gray-200 dark:border-gray-700">
                        <h2 className="text-xl font-bold text-transparent bg-gradient-to-r from-gray-900 to-gray-700 dark:from-white dark:to-gray-300 bg-clip-text">
                            Mathematics
                        </h2>
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                            Build your custom exam paper
                        </p>
                    </div>

                    {/* Scrollable Content Area */}
                    <div className="flex flex-col flex-1 overflow-y-auto">
                        {/* Level Selection */}
                        <div className="p-4 space-y-3">
                            <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                                Academic Level
                            </h3>
                            <div className="grid grid-cols-1 gap-2">
                                {Object.keys(levelLabels).map((level) => (
                                    level !== AcademicLevel.GCSE && (
                                        <button
                                            key={level}
                                            onClick={() => handleLevelChange(level as 'gcse' | 'aslevel' | 'alevel')}
                                            className={`relative p-4 rounded-xl transition-all duration-300 ${
                                                selectedLevel === level
                                                    ? 'bg-gradient-to-r from-green-500 to-green-600 text-white shadow-lg shadow-green-500/25'
                                                    : 'bg-gray-50 hover:bg-gray-100 dark:bg-gray-700/50 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-200'
                                            }`}
                                        >
                                            <span className="font-medium">{levelLabels[level as AcademicLevel]}</span>
                                            {selectedLevel === level && (
                                                <div className="absolute -translate-y-1/2 right-4 top-1/2">
                                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                                                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                                                    </svg>
                                                </div>
                                            )}
                                        </button>
                                    )
                                ))}
                            </div>
                        </div>

                        {/* Topics Section */}
                        <div className="flex-1 p-4 space-y-3">
                            <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                                Topics
                            </h3>
                            <div className="space-y-2">
                                {loadingTopics ? (
                                    <div className="flex items-center justify-center py-4">
                                        <div className="w-2 h-2 bg-green-500 rounded-full animate-bounce"/>
                                        <div className="w-2 h-2 delay-100 bg-green-500 rounded-full animate-bounce"/>
                                        <div className="w-2 h-2 delay-200 bg-green-500 rounded-full animate-bounce"/>
                                    </div>
                                ) : (
                                    topics.map(topic => (
                                        <label
                                            key={topic.value}
                                            className="flex items-center p-3 space-x-3 transition-all duration-200 rounded-lg cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700/50"
                                        >
                                            <div className="relative flex items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedTopics.some(t => t.value === topic.value)}
                                                    onChange={() => handleTopicChange(topic)}
                                                    className="w-5 h-5 text-green-500 border-2 rounded-md focus:ring-green-500 dark:border-gray-600 dark:bg-gray-700"
                                                />
                                                {selectedTopics.some(t => t.value === topic.value) && (
                                                    <motion.div
                                                        initial={{ scale: 0 }}
                                                        animate={{ scale: 1 }}
                                                        className="absolute inset-0 flex items-center justify-center">
                                                        <div className="w-2 h-2 bg-green-500 rounded-full"/>
                                                    </motion.div>
                                                )}
                                            </div>
                                            <span className="text-gray-700 dark:text-gray-200">
                                                {topic.label}
                                            </span>
                                        </label>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Fixed Bottom Actions */}
                    <div className="sticky bottom-0 p-4 bg-white border-t border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                        {selectedTopics.length > 0 && !isFetchingQuestions && (
                            <button
                                onClick={handleContinue}
                                className="w-full px-4 py-3 mb-2 font-medium text-white transition-all duration-300 rounded-lg shadow-lg bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700"
                            >
                                Continue with {selectedTopics.length} topic{selectedTopics.length > 1 ? 's' : ''}
                            </button>
                        )}
                        {!isResetDisabled && (
                            <button
                                onClick={handleReset}
                                className="w-full px-4 py-3 font-medium text-gray-700 transition-all duration-300 rounded-lg dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                            >
                                Reset Selection
                            </button>
                        )}
                    </div>
                </div>

                {/* Main Content Area */}
                <div className="flex flex-1 h-full min-w-0">
                    {/* Questions List */}
                    <div className="flex flex-col w-1/3 min-w-[300px] h-full border-r border-gray-200 dark:border-gray-700 bg-white/80 dark:bg-gray-800/80">
                        <div className="flex-shrink-0 p-4 border-b border-gray-200 dark:border-gray-700">
                            <div className="flex items-center justify-between">
                                <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Questions</h2>
                                {basket.length > 0 && (
                                    <button
                                        onClick={() => setShowBasketPreview(true)}
                                        className="px-3 py-1 text-sm text-white bg-green-500 rounded-full hover:bg-green-600"
                                    >
                                        {basket.length} Selected
                                    </button>
                                )}
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search questions..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="w-full px-4 py-2 pl-10 border rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                                />
                                <svg className="absolute w-5 h-5 text-gray-400 left-3 top-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                </svg>
                            </div>
                        </div>
                        <div className="flex-1 overflow-y-auto">
                            {loading ? (
                                <div className="flex items-center justify-center py-4">
                                    <div className="w-2 h-2 bg-green-500 rounded-full animate-bounce"/>
                                    <div className="w-2 h-2 delay-100 bg-green-500 rounded-full animate-bounce"/>
                                    <div className="w-2 h-2 delay-200 bg-green-500 rounded-full animate-bounce"/>
                                </div>
                            ) : (
                                filteredQuestions.map((question, index) => (
                                    <motion.div
                                        key={question.id}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.3, delay: index * 0.05 }}
                                        onClick={() => handleQuestionSelect(index)}
                                        className={`p-4 cursor-pointer rounded-lg transition-all transform hover:scale-102 ${
                                            selectedQuestionIndex === index 
                                                ? 'bg-green-50 dark:bg-gray-700 border-l-4 border-green-500 shadow-lg' 
                                                : 'hover:bg-gray-50 dark:hover:bg-gray-700'
                                        }`}
                                    >
                                        <div className="flex items-center justify-between">
                                            <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                                                {question.name}
                                            </h3>
                                            {basket.includes(question.id) && (
                                                <span className="px-2 py-1 text-xs text-white bg-green-500 rounded-full">Added</span>
                                            )}
                                        </div>
                                    </motion.div>
                                ))
                            )}
                        </div>
                    </div>

                    {/* Preview Area */}
                    <div className="flex flex-col flex-1 h-full overflow-hidden bg-gray-50 dark:bg-gray-900">
                        <div className="flex flex-col h-full p-4 overflow-hidden">
                            {selectedQuestionIndex !== null && questions ? (
                                <div className="flex flex-col h-full">
                                    <div className="flex flex-wrap items-center justify-between gap-4 mb-4">
                                        <h2 className="text-lg font-bold text-gray-900 md:text-xl dark:text-white">
                                            {questions.questionName[selectedQuestionIndex]}
                                        </h2>
                                        <div className="flex flex-wrap items-center gap-2">
                                            {/* Zoom controls - Made more touch-friendly */}
                                            <div className="flex items-center p-2 space-x-2 rounded-lg shadow-sm bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm">
                                                <button
                                                    onClick={() => handleZoomChange(
                                                        questions.questionIds[selectedQuestionIndex],
                                                        (zoomStates[questions.questionIds[selectedQuestionIndex]]?.scale || 1) - 0.1
                                                    )}
                                                    className="p-3 text-gray-600 rounded-lg hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
                                                >
                                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4"/>
                                                    </svg>
                                                </button>
                                                <button
                                                    onClick={() => handleZoomToFit(questions.questionIds[selectedQuestionIndex])}
                                                    className="px-3 py-2 text-sm text-gray-600 rounded-lg hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
                                                >
                                                    {Math.round((zoomStates[questions.questionIds[selectedQuestionIndex]]?.scale || 1) * 100)}%
                                                </button>
                                                <button
                                                    onClick={() => handleZoomChange(
                                                        questions.questionIds[selectedQuestionIndex],
                                                        (zoomStates[questions.questionIds[selectedQuestionIndex]]?.scale || 1) + 0.1
                                                    )}
                                                    className="p-3 text-gray-600 rounded-lg hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
                                                >
                                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4"/>
                                                    </svg>
                                                </button>
                                            </div>
                                            <button
                                                onClick={() => setBasket(prev => 
                                                    prev.includes(questions.questionIds[selectedQuestionIndex])
                                                        ? prev.filter(id => id !== questions.questionIds[selectedQuestionIndex])
                                                        : [...prev, questions.questionIds[selectedQuestionIndex]]
                                                )}
                                                className={`px-4 py-3 rounded-lg transition-colors ${
                                                    basket.includes(questions.questionIds[selectedQuestionIndex])
                                                        ? 'bg-green-500 text-white'
                                                        : 'bg-blue-500 text-white'
                                                }`}
                                            >
                                                {basket.includes(questions.questionIds[selectedQuestionIndex]) 
                                                    ? 'Remove from Paper' 
                                                    : 'Add to Paper'}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="relative flex-1 min-h-0 overflow-hidden bg-gray-100 rounded-lg dark:bg-gray-800" ref={containerRef}>
                                        <div 
                                            className="relative w-full h-full overflow-auto"
                                            onDoubleClick={() => handleZoomToFit(questions.questionIds[selectedQuestionIndex])}
                                        >
                                            <div className="flex justify-center min-h-full p-4">
                                                <div style={{ 
                                                    transform: `scale(${zoomStates[questions.questionIds[selectedQuestionIndex]]?.scale || 1})`,
                                                    transformOrigin: 'center top',
                                                    transition: 'transform 0.2s ease-out'
                                                }}>
                                                    <img
                                                        ref={imageRef}
                                                        src={questions.previewUrls[selectedQuestionIndex]}
                                                        alt="Question preview"
                                                        onLoad={() => handleImageLoad(questions.questionIds[selectedQuestionIndex])}
                                                        className="rounded-lg shadow-lg max-w-none"
                                                        style={{ maxHeight: 'none' }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex items-center justify-center h-full text-gray-500 dark:text-gray-400">
                                    Select a question to preview
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Mobile Bottom Navigation */}
                <div className="fixed bottom-0 left-0 right-0 flex justify-center p-4 bg-white border-t border-gray-200 dark:bg-gray-800 dark:border-gray-700 md:hidden">
                    {basket.length > 0 && (
                        <button
                            onClick={() => setShowBasketPreview(true)}
                            className="px-6 py-3 text-white bg-green-500 rounded-lg shadow-lg"
                        >
                            Review Paper ({basket.length})
                        </button>
                    )}
                </div>

                {/* Basket Preview Modal */}
                {showBasketPreview && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <motion.div 
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 1, scale: 1 }}
                            className="w-full max-w-2xl p-6 bg-white rounded-lg dark:bg-gray-800"
                        >
                            <div className="flex items-center justify-between mb-4">
                                <h2 className="text-xl font-bold text-gray-900 dark:text-white">Selected Questions</h2>
                                <button 
                                    onClick={() => setShowBasketPreview(false)}
                                    className="text-gray-500 hover:text-gray-700 dark:text-gray-400"
                                >
                                    ×
                                </button>
                            </div>
                            <div className="max-h-[60vh] overflow-y-auto">
                                {basket.map((id) => {
                                    const question = filteredQuestions.find(q => q.id === id);
                                    return question && (
                                        <div key={id} className="p-4 mb-2 rounded-lg bg-gray-50 dark:bg-gray-700">
                                            <div className="flex items-center justify-between">
                                                <span className="text-gray-900 dark:text-white">{question.name}</span>
                                                <button
                                                    onClick={() => handleRemoveFromBasket(id)}
                                                    className="px-3 py-1 text-sm text-white bg-red-500 rounded-lg hover:bg-red-600"
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="flex justify-end mt-4 space-x-4">
                                <button
                                    onClick={() => setShowBasketPreview(false)}
                                    className="px-4 py-2 text-gray-600 bg-gray-200 rounded-lg dark:bg-gray-700 dark:text-gray-300"
                                >
                                    Close
                                </button>
                                <button
                                    onClick={handleBuildExamPaper}
                                    disabled={isBuildingPaper}
                                    className={`px-6 py-2 text-white rounded-lg ${
                                        isBuildingPaper ? 'bg-gray-500' : 'bg-green-500 hover:bg-green-600'
                                    }`}
                                >
                                    {isBuildingPaper ? (
                                        <div className="flex items-center space-x-2">
                                            <div className="w-4 h-4 border-2 border-white rounded-full border-t-transparent animate-spin"></div>
                                            <span>Building...</span>
                                        </div>
                                    ) : (
                                        'Build Paper'
                                    )}
                                </button>
                            </div>
                        </motion.div>
                    </div>
                )}

                {/* Build Paper Button */}
                {basket.length > 0 && !showBasketPreview && (
                    <motion.div 
                        className="fixed z-50 bottom-4 right-4"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                    >
                        <button
                            onClick={() => setShowBasketPreview(true)}
                            className="px-6 py-3 text-white transition-all bg-green-500 rounded-lg shadow-lg hover:bg-green-600"
                        >
                            Review Paper ({basket.length})
                        </button>
                    </motion.div>
                )}
            </div>

            <style>
                {`
                    /* Custom Scrollbar */
                    .overflow-y-auto::-webkit-scrollbar {
                        width: 6px;
                    }
                    
                    .overflow-y-auto::-webkit-scrollbar-track {
                        background: ${isDarkMode ? '#1a1a1a' : '#f1f1f1'};
                        border-radius: 3px;                    }
                    
                    .overflow-y-auto::-webkit-scrollbar-thumb {
                        background: ${isDarkMode ? '#444' : '#888'};
                        border-radius: 3px;
                    }
                    
                    .overflow-y-auto::-webkit-scrollbar-thumb:hover {
                        background: ${isDarkMode ? '#555' : '#999'};
                    }
                `}
            </style>
        </div>
    );
};

export default memo(ExamPaperBuilder);