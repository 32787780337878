import { FaBars } from "@react-icons/all-files/fa/FaBars";
import { FaCalculator } from "@react-icons/all-files/fa/FaCalculator";
import { FaCog } from "@react-icons/all-files/fa/FaCog";
import { FaHome } from "@react-icons/all-files/fa/FaHome";
import { FaMoon } from "@react-icons/all-files/fa/FaMoon";
import { FaSignInAlt } from "@react-icons/all-files/fa/FaSignInAlt";
import { FaSignOutAlt } from "@react-icons/all-files/fa/FaSignOutAlt";
import { FaSun } from "@react-icons/all-files/fa/FaSun";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { FaUser } from "@react-icons/all-files/fa/FaUser";
import { FaUserPlus } from "@react-icons/all-files/fa/FaUserPlus";
import React, { memo, useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router";
import { Link, useLocation } from 'react-router-dom';
import Url from "../utils/Url";
import useAuth from "../utils/useAuth";
import { useTheme } from "./ThemeContext";

interface NavBarProps {
    role?: string | null;
}

const NavBar: React.FC<NavBarProps> = ({ role }) => {
    const { isDarkMode, toggleTheme, theme } = useTheme();
    const navigate = useNavigate();
    const { jwt, logout } = useAuth(navigate);
    const [isOpen, setIsOpen] = useState(false);
    const [isMathMenuOpen, setMathMenuOpen] = useState(false);
    const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);
    const location = useLocation();
    const mathMenuRef = useRef<HTMLDivElement>(null);
    const menuTimeoutRef = useRef<NodeJS.Timeout>();
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    // State for dynamic padding
    const [paddingClass, setPaddingClass] = useState("pt-16"); // Set the initial padding

    const toggleMenu = () => setIsOpen(!isOpen);

    const navItemClass = `px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
        isDarkMode
            ? 'text-gray-300 hover:bg-gray-700 hover:text-white'
            : 'text-gray-700 hover:bg-gray-200 hover:text-black'
    }`;

    const dropdownItemClass = `block px-4 py-2 text-sm transition-colors duration-200 ${
        isDarkMode ? 'text-gray-300 hover:bg-gray-600' : 'text-gray-700 hover:bg-gray-100'
    }`;

    interface NavLinkProps {
        to: string;
        onClick?: () => void;
        className?: string;
        children: React.ReactNode;
    }

    const NavLink: React.FC<NavLinkProps> = ({ to, onClick, className, children }) => {
        return (
            <Link to={to} onClick={onClick} className={className}>
                {children}
            </Link>
        );
    };

    const isActive = (path: string) => {
        if (isMobileView) return '';
        return location.pathname === path ? 'text-green-500' : '';
    };

    const logoPath = isDarkMode ? "/logos/dark_mode_logo.svg" : "/logos/white_mode_logo.svg";

    const handleMouseEnter = () => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
            setHoverTimeout(null);
        }
        setMathMenuOpen(true);
    };

    const handleMouseLeave = () => {
        const timeoutId = setTimeout(() => {
            setMathMenuOpen(false);
        }, 300);
        setHoverTimeout(timeoutId);
    };

    // Update padding based on menu state
    useEffect(() => {
        setPaddingClass(isOpen ? "pt-16" : "pt-0"); // Adjust this based on your layout
    }, [isOpen]);

    const handleLogout = () => {
        logout(); // This will now clear both role and dyslexia caches
        toggleMenu();
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (mathMenuRef.current && !mathMenuRef.current.contains(event.target as Node)) {
                setMathMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleMathMenuEnter = () => {
        if (menuTimeoutRef.current) {
            clearTimeout(menuTimeoutRef.current);
        }
        setMathMenuOpen(true);
    };

    const handleMathMenuLeave = () => {
        menuTimeoutRef.current = setTimeout(() => {
            setMathMenuOpen(false);
        }, 150);
    };

    const handleMathMenuClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (isMobileView) {
            setMathMenuOpen(prev => !prev);
        }
    };

    const renderMathMenu = () => (
        <div
            className="relative group"
            onMouseEnter={!isMobileView ? handleMathMenuEnter : undefined}
            onMouseLeave={!isMobileView ? handleMathMenuLeave : undefined}
            ref={mathMenuRef}
        >
            <button 
                onClick={handleMathMenuClick}
                className={`${isActive(Url.MATH)} ${navItemClass} w-full text-left flex items-center justify-between`}
            >
                <span>
                    <FaCalculator className="inline mr-1" /> Math
                </span>
                <svg
                    className={`w-4 h-4 ml-2 transition-transform duration-200 ${isMathMenuOpen ? 'rotate-180' : ''}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
            </button>
            
            <div
                className={`
                    ${isMathMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'}
                    ${isMobileView ? 'static w-full pl-4' : 'absolute left-0 mt-2 w-56'}
                    transition-all duration-200 ease-in-out
                    ${isDarkMode ? 'bg-gray-800' : 'bg-white'}
                    ${!isMobileView ? 'rounded-md shadow-lg ring-1 ring-black ring-opacity-5' : ''}
                    z-50
                `}
                onMouseEnter={!isMobileView ? handleMathMenuEnter : undefined}
                onMouseLeave={!isMobileView ? handleMathMenuLeave : undefined}
            >
                {[
                    { to: Url.MATH, text: 'Math Home' },
                    { to: Url.MATH_EXAM_PAPER_RETRIEVAL, text: 'Exam Paper Retrieval' },
                    { to: Url.MATH_EXAM_PAPER_BUILDER, text: 'Exam Paper Builder' },
                    { to: Url.MATH_TOPIC_PAPER_GENERATOR, text: 'Topic Paper Generator' },
                    ...(role === 'ADMIN' ? [{ to: Url.MATH_EXAM_QUESTION_UPLOADER, text: 'Question Uploader' }] : [])
                ].map((item, index) => (
                    <NavLink
                        key={item.to}
                        to={item.to}
                        onClick={() => {
                            setMathMenuOpen(false);
                            setIsOpen(false);
                        }}
                        className={`
                            ${dropdownItemClass}
                            block w-full px-4 py-2
                            ${index !== 0 ? 'border-t border-gray-100 dark:border-gray-700' : ''}
                            hover:bg-gray-50 dark:hover:bg-gray-700
                            transition-colors duration-150
                        `}
                    >
                        {item.text}
                    </NavLink>
                ))}
            </div>
        </div>
    );

    const mobileNavItemClass = `
        w-full px-4 py-3 flex items-center justify-between
        text-base font-medium
        ${isDarkMode 
            ? 'text-gray-300 active:bg-gray-700/50 border-gray-700' 
            : 'text-gray-700 active:bg-gray-100/50 border-gray-200'}
        border-b
        -webkit-tap-highlight-color: transparent
        touch-action: manipulation
    `;

    const mobileMathContainerClass = `
        relative select-none border-b
        ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}
        ${isMathMenuOpen && isDarkMode ? 'bg-gray-700/30' : 
        isMathMenuOpen ? 'bg-gray-100/30' : ''}
    `;

    const mobileMathButtonClass = `
        flex items-center justify-between w-full
        px-4 py-3 text-base font-medium
        ${isDarkMode 
            ? 'text-gray-300 active:bg-gray-700/50' 
            : 'text-gray-700 active:bg-gray-100/50'}
        focus:outline-none
        -webkit-tap-highlight-color: transparent
        touch-action: manipulation
    `;

    useEffect(() => {
        if (!isMobileView) {
            setMathMenuOpen(false);
        }
    }, [isMobileView]);

    useEffect(() => {
        if (!isOpen) {
            setMathMenuOpen(false);
        }
    }, [isOpen]);

    const renderMobileMenu = () => (
        <>
            {/* Backdrop overlay */}
            <div 
                className={`fixed inset-0 bg-black transition-opacity duration-300 md:hidden
                    ${isOpen ? 'opacity-50 visible' : 'opacity-0 invisible'}`}
                onClick={() => setIsOpen(false)}
            />

            {/* Mobile menu panel */}
            <div 
                className={`
                    fixed top-0 right-0 w-[280px] h-full md:hidden
                    ${theme.cardBg} shadow-xl overflow-y-auto z-50
                    transform transition-transform duration-300 ease-in-out
                    ${isOpen ? 'translate-x-0' : 'translate-x-full'}
                `}
            >
                {/* Mobile menu header */}
                <div className={`flex items-center justify-between p-4 border-b ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}>
                    <Link to={Url.HOME} className="flex-shrink-0" onClick={() => setIsOpen(false)}>
                        <img className="w-8 h-8" src={logoPath} alt="Logo" />
                    </Link>
                    <button
                        onClick={() => setIsOpen(false)}
                        className={`p-2 rounded-md ${isDarkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}
                    >
                        <FaTimes className={`w-6 h-6 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`} />
                    </button>
                </div>

                {/* Mobile menu items */}
                <div className="py-2">
                    <NavLink to={Url.HOME} 
                        onClick={() => setIsOpen(false)} 
                        className={mobileNavItemClass}
                    >
                        <span><FaHome className="inline mr-3" /> Home</span>
                    </NavLink>

                    {jwt ? (
                        <>
                            <div className={mobileMathContainerClass}>
                                <button 
                                    onClick={handleMathMenuClick}
                                    className={mobileMathButtonClass}
                                >
                                    <span className="flex items-center">
                                        <FaCalculator className="mr-3" /> Math
                                    </span>
                                    <svg
                                        className={`w-5 h-5 transition-transform duration-200 
                                            ${isMathMenuOpen ? 'rotate-180' : ''} 
                                            ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                    </svg>
                                </button>

                                <div 
                                    className={`
                                        overflow-hidden transition-all duration-300 ease-in-out
                                        ${isMathMenuOpen ? 'max-h-[400px] opacity-100' : 'max-h-0 opacity-0'}
                                        ${isDarkMode ? 'bg-gray-800/50' : 'bg-gray-50/50'}
                                    `}
                                >
                                    {[
                                        { to: Url.MATH, text: 'Math Home' },
                                        { to: Url.MATH_EXAM_PAPER_RETRIEVAL, text: 'Exam Paper Retrieval' },
                                        { to: Url.MATH_EXAM_PAPER_BUILDER, text: 'Exam Paper Builder' },
                                        { to: Url.MATH_TOPIC_PAPER_GENERATOR, text: 'Topic Paper Generator' },
                                        ...(role === 'ADMIN' ? [{ to: Url.MATH_EXAM_QUESTION_UPLOADER, text: 'Question Uploader' }] : [])
                                    ].map((item, index, array) => (
                                        <NavLink
                                            key={item.to}
                                            to={item.to}
                                            onClick={() => {
                                                setMathMenuOpen(false);
                                                setIsOpen(false);
                                            }}
                                            className={`
                                                block w-full px-6 py-3
                                                text-sm transition-colors
                                                ${isDarkMode 
                                                    ? 'text-gray-400 active:bg-gray-700/50' 
                                                    : 'text-gray-600 active:bg-gray-100/50'}
                                                ${index !== array.length - 1 ? 'border-b border-gray-700/20' : ''}
                                                -webkit-tap-highlight-color: transparent
                                            `}
                                        >
                                            {item.text}
                                        </NavLink>
                                    ))}
                                </div>
                            </div>

                            {/* Other nav items */}
                            <NavLink to={Url.USER_PAGE} onClick={() => setIsOpen(false)} className={mobileNavItemClass}>
                                <span><FaUser className="inline mr-3" /> User</span>
                            </NavLink>

                            <NavLink to={Url.SETTINGS_PAGE} onClick={() => setIsOpen(false)} className={mobileNavItemClass}>
                                <span><FaCog className="inline mr-3" /> Settings</span>
                            </NavLink>

                            <NavLink to={Url.REVISION_TIMER} onClick={() => setIsOpen(false)} className={mobileNavItemClass}>
                                <span><FaTimes className="inline mr-3" /> Revision Timer</span>
                            </NavLink>

                            <button onClick={handleLogout} className={mobileNavItemClass}>
                                <span><FaSignOutAlt className="inline mr-3" /> Logout</span>
                            </button>
                        </>
                    ) : (
                        <>
                            <NavLink to={Url.LOGIN} onClick={() => setIsOpen(false)} className={mobileNavItemClass}>
                                <span><FaSignInAlt className="inline mr-3" /> Login</span>
                            </NavLink>
                            <NavLink to={Url.SIGN_UP} onClick={() => setIsOpen(false)} className={mobileNavItemClass}>
                                <span><FaUserPlus className="inline mr-3" /> Sign Up</span>
                            </NavLink>
                        </>
                    )}
                </div>
            </div>
        </>
    );

    return (
        <nav className={`sticky top-0 left-0 right-0 z-50 ${theme.cardBg} shadow-lg transition-colors duration-200`}>
            <div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${paddingClass}`}>
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <Link to={Url.HOME} className="flex-shrink-0">
                            <img className="w-8 h-8" src={logoPath} alt="Logo" />
                        </Link>
                    </div>
                    <div className="hidden md:block">
                        <div className={`flex items-baseline ml-10 space-x-4 ${theme.text}`}>
                            <NavLink to={Url.HOME} className={`${isActive(Url.HOME)} ${navItemClass}`}>
                                <FaHome className="inline mr-1" /> Home
                            </NavLink>

                            {jwt ? (
                                <>
                                    {renderMathMenu()}

                                    <NavLink to={Url.USER_PAGE} className={`${isActive(Url.USER_PAGE)} ${navItemClass}`}>
                                        <FaUser className="inline mr-1" /> User
                                    </NavLink>

                                    <NavLink to={Url.SETTINGS_PAGE} className={`${isActive(Url.SETTINGS_PAGE)} ${navItemClass}`}>
                                        <FaCog className="inline mr-1" /> Settings
                                    </NavLink>

                                    <NavLink to={Url.REVISION_TIMER} className={`${isActive(Url.REVISION_TIMER)} ${navItemClass}`}>
                                        <FaTimes className="inline mr-1" /> Revision Timer
                                    </NavLink>

                                    <button onClick={handleLogout} className={navItemClass}>
                                        <FaSignOutAlt className="inline mr-1" /> Logout
                                    </button>
                                </>
                            ) : (
                                <>
                                    <NavLink to={Url.LOGIN} className={navItemClass}>
                                        <FaSignInAlt className="inline mr-1" /> Login
                                    </NavLink>
                                    <NavLink to={Url.SIGN_UP} className={navItemClass}>
                                        <FaUserPlus className="inline mr-1" /> Sign Up
                                    </NavLink>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center">
                        <button
                            onClick={toggleTheme}
                            className={`p-2 rounded-md transition-colors duration-200 ${
                                isDarkMode ? 'bg-gray-700 hover:bg-gray-600' : 'bg-gray-200 hover:bg-gray-300'
                            }`}
                        >
                            {isDarkMode ? <FaSun className="text-amber-400"/> : <FaMoon className="text-gray-700"/>}
                        </button>
                        <div className="flex -mr-2 md:hidden">
                            <button
                                onClick={toggleMenu}
                                className={`inline-flex items-center justify-center p-2 rounded-md transition-colors duration-200 ${
                                    isDarkMode ? 'text-gray-400 hover:text-white hover:bg-gray-700' : 'text-gray-700 hover:text-black hover:bg-gray-200'
                                } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
                            >
                                <span className="sr-only">Open main menu</span>
                                {isOpen ? <FaTimes className="block w-6 h-6" /> : <FaBars className="block w-6 h-6" />}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {renderMobileMenu()}

            <style>
                {`
                    .bubble-effect {
                        transform-origin: top;
                        animation: bubbleIn 0.2s ease-out;
                    }
                    
                    @keyframes bubbleIn {
                        0% {
                            transform: scale(0.95);
                            opacity: 0;
                        }
                        100% {
                            transform: scale(1);
                            opacity: 1;
                        }
                    }

                    .dropdown-enter {
                        opacity: 0;
                        transform: translateY(-10px);
                    }
                    
                    .dropdown-enter-active {
                        opacity: 1;
                        transform: translateY(0);
                        transition: opacity 200ms, transform 200ms;
                    }
                    
                    .dropdown-exit {
                        opacity: 1;
                        transform: translateY(0);
                    }
                    
                    .dropdown-exit-active {
                        opacity: 0;
                        transform: translateY(-10px);
                        transition: opacity 200ms, transform 200ms;
                    }

                    /* Remove tap highlight color */
                    * {
                        -webkit-tap-highlight-color: transparent;
                    }
                    
                    /* Prevent text selection in mobile menu */
                    .select-none {
                        user-select: none;
                        -webkit-user-select: none;
                    }
                    
                    /* Smooth transitions for mobile menu */
                    .mobile-menu-transition {
                        transition: all 0.3s ease-in-out;
                    }
                `}
            </style>
        </nav>
    );
};

export default memo(NavBar);