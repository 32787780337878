import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import { addMinutes, format } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTheme } from "../components/ThemeContext";
import { TimerAPIUrls } from "../utils/APIUrls";
import { getCurrentButtonStyles } from "../utils/Common";
import { getCookie } from "../utils/Cookies";
import {
  calculateBackgroundStyle,
  getBackgroundImages,
  getImageDimensions,
} from "../utils/ImageUtils";
import { getWebsiteUrl } from "../utils/Url";

declare global {
  interface Window {
    onYouTubeIframeAPIReady?: () => void;
  }
}

interface Task {
  taskId: string;
  taskName: string;
  timeLeft: number;
  duration: number;
}

interface CompletedTask {
  taskId: string;
  taskName: string;
  duration: number;
}

interface Background {
  type: "color" | "image" | "youtube";
  value: string;
  isMuted?: boolean;
}

const RevisionTimer: React.FC = () => {
  const { isDarkMode } = useTheme();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [currentTask, setCurrentTask] = useState<Task | null>(null);
  const [isRunning, setIsRunning] = useState(false);
  const [background, setBackground] = useState<Background>({
    type: "image",
    value: "/backgrounds/main.jpg",
  });
  const [newTask, setNewTask] = useState({ name: "", duration: 0 });
  const jwtToken = getCookie("jwt");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [tempBackground, setTempBackground] = useState<Background>(background);
  const [selectedBackgroundType, setSelectedBackgroundType] = useState<
    "color" | "image" | "youtube"
  >("color");
  const [estimatedFinishTime, setEstimatedFinishTime] = useState<Date | null>(
    null
  );
  const [imageOptions, setImageOptions] = useState<string[]>([]);
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [backgroundStyle, setBackgroundStyle] = useState({});
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [inputError, setInputError] = useState<string>("");
  const [youtubeError, setYoutubeError] = useState<string>("");

  const [completedTasks, setCompletedTasks] = useState<CompletedTask[]>([]);
  const [showCompletedTasks, setShowCompletedTasks] = useState(false);

  const [isCompletedTasksSidebarOpen, setIsCompletedTasksSidebarOpen] =
    useState(false);
  const [isTasksSidebarOpen, setIsTasksSidebarOpen] = useState(false);

  const [selectedImagePreview, setSelectedImagePreview] = useState<
    string | null
  >(null);

  const [youtubeIframe, setYoutubeIframe] = useState<HTMLIFrameElement | null>(
    null
  );

  const [isYoutubeReady, setIsYoutubeReady] = useState(false);

  const [isLoadingCompletedTasks, setIsLoadingCompletedTasks] = useState(false);

  const [isDyslexiaMode] = useState(() => {
    const saved = localStorage.getItem("dyslexiaMode");
    return saved ? JSON.parse(saved) : false;
  });

  const [isAddingTask, setIsAddingTask] = useState(false);
  const [isStartingTask, setIsStartingTask] = useState<string | null>(null);

  const [showSwitchTaskModal, setShowSwitchTaskModal] = useState(false);
  const [taskToSwitchTo, setTaskToSwitchTo] = useState<Task | null>(null);

  const [newVideoMuted, setNewVideoMuted] = useState(true);

  const handleForcePlay = useCallback(() => {
    if (!youtubeIframe || !isYoutubeReady) return;

    // Try multiple message formats and commands
    const commands = [
        { event: 'command', func: 'playVideo' },
        { event: 'cmd', func: 'playVideo' },
        'playVideo',
        { method: 'play' },
        { command: 'playVideo' }
    ];

    commands.forEach(cmd => {
        try {
            // Try sending command in different formats
            youtubeIframe.contentWindow?.postMessage(cmd, '*');
            youtubeIframe.contentWindow?.postMessage(JSON.stringify(cmd), '*');
        } catch (error) {
            console.error('Error sending play command:', error);
        }
    });

    // Direct iframe manipulation attempt
    try {
        const url = youtubeIframe.src;
        youtubeIframe.src = '';
        youtubeIframe.src = url;
    } catch (error) {
        console.error('Error reloading iframe:', error);
    }
}, [youtubeIframe, isYoutubeReady]);

  const lastUpdateTimeRef = useRef<number>(Date.now());

  const initialTimeRef = useRef<number>(0);

  useEffect(() => {
    const savedTask = localStorage.getItem("currentTask");
    if (savedTask) {
      const parsedTask = JSON.parse(savedTask);
      setCurrentTask(parsedTask);
      if (parsedTask.timeLeft > 0) {
        setIsRunning(false);
        setEstimatedFinishTime(
          addMinutes(new Date(), Math.ceil(parsedTask.timeLeft / 60))
        );
      }
    }
  }, []);

  useEffect(() => {
    if (currentTask) {
      localStorage.setItem("currentTask", JSON.stringify(currentTask));
    } else {
      localStorage.removeItem("currentTask");
    }
  }, [currentTask]);

  useEffect(() => {
    const loadBackgroundImages = async () => {
      setIsLoadingImages(true);
      try {
        const images = await getBackgroundImages();
        setImageOptions(images);
      } catch (error) {
        console.error("Error loading background images:", error);
        setImageOptions(["/backgrounds/main.jpeg"]);
      }
      setIsLoadingImages(false);
    };

    loadBackgroundImages();
  }, []);

  const isSaveButtonDisabled = () => {
    if (selectedBackgroundType === "image" && !tempBackground.value) {
      return true;
    }
    if (
      selectedBackgroundType === "youtube" &&
      (!tempBackground.value || tempBackground.type !== "youtube")
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch(TimerAPIUrls.ACTIVE_TASKS, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        });
        const data = await response.json();

        const savedTaskString = localStorage.getItem("currentTask");
        if (savedTaskString) {
          const savedTask = JSON.parse(savedTaskString);
          setCurrentTask(savedTask);
          setTasks(
            data.filter((task: Task) => task.taskId !== savedTask.taskId)
          );
          if (savedTask.timeLeft > 0) {
            setEstimatedFinishTime(
              addMinutes(new Date(), Math.ceil(savedTask.timeLeft / 60))
            );
          }
        } else {
          setTasks(data);
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
  }, [jwtToken]);

  useEffect(() => {
    const loadBackgroundPreference = async () => {
      try {
        const response = await fetch(TimerAPIUrls.BACKGROUND, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        });

        if (!response.ok || response.status === 204) {
          const defaultBackground = {
            type: "image" as const,
            value: "/backgrounds/main.jpg",
          };
          setBackground(defaultBackground);
          setTempBackground(defaultBackground);
          const { width, height } = await getImageDimensions(
            defaultBackground.value
          );
          const style = calculateBackgroundStyle(
            width,
            height,
            window.innerWidth,
            window.innerHeight
          );
          setBackgroundStyle({
            ...style,
            backgroundImage: `url(${defaultBackground.value})`,
            backgroundSize: "cover",
          });
          return;
        }

        const data = await response.json();

        const backgroundType = data.preferenceType.toLowerCase();
        if (
          backgroundType !== "color" &&
          backgroundType !== "image" &&
          backgroundType !== "youtube"
        ) {
          throw new Error("Invalid background type");
        }

        let backgroundValue = data.value;

        if (backgroundType === "youtube") {
          let videoUrl = backgroundValue;
          const isMuted = data.isMuted ?? false;

          if (isMuted) {
            const separator = videoUrl.includes("?") ? "&" : "?";
            videoUrl = `${videoUrl}${separator}mute=1`;
          }

          const videoBackground: Background = {
            type: "youtube",
            value: videoUrl,
            isMuted: isMuted,
          };

          setBackground(videoBackground);
          setTempBackground(videoBackground);
          setIsVideoMuted(isMuted);
          return;
        } else if (backgroundType === "image") {
          const { width, height } = await getImageDimensions(backgroundValue);
          const style = calculateBackgroundStyle(
            width,
            height,
            window.innerWidth,
            window.innerHeight
          );

          setBackgroundStyle({
            ...style,
            backgroundImage: `url(${backgroundValue})`,
            backgroundSize: "cover",
          });

          const imageBackground: Background = {
            type: backgroundType as "image",
            value: backgroundValue,
          };

          setBackground(imageBackground);
          setTempBackground(imageBackground);
        } else if (backgroundType === "color") {
          setBackgroundStyle({
            backgroundColor: backgroundValue,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100vw",
            height: "100vh",
          });
          const colourBackground: Background = {
            type: backgroundType as "image",
            value: backgroundValue,
          };

          setBackground(colourBackground);
          setTempBackground(colourBackground);
        } else {
          const defaultBackground = {
            type: "image" as const,
            value: "/backgrounds/main.jpg",
          };
          setBackground(defaultBackground);
          setTempBackground(defaultBackground);
          const { width, height } = await getImageDimensions(
            defaultBackground.value
          );
          const style = calculateBackgroundStyle(
            width,
            height,
            window.innerWidth,
            window.innerHeight
          );
          setBackgroundStyle({
            ...style,
            backgroundImage: `url(${defaultBackground.value})`,
            backgroundSize: "cover",
          });
        }
        return;
      } catch (error) {
        console.error("Error loading background from API:", error);
      }
    };

    loadBackgroundPreference();
  }, [jwtToken]);

  useEffect(() => {
    const handleResize = async () => {
      if (background.type === "image") {
        const { width, height } = await getImageDimensions(background.value);
        const style = calculateBackgroundStyle(
          width,
          height,
          window.innerWidth,
          window.innerHeight
        );
        setBackgroundStyle(style);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [background]);

  useEffect(() => {
    const fetchCompletedTasks = async () => {
      setIsLoadingCompletedTasks(true);
      try {
        const response = await fetch(TimerAPIUrls.COMPLETED_TASKS, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        });
        const data = await response.json();
        setCompletedTasks(data);
      } catch (error) {
        console.error("Error fetching completed tasks:", error);
      } finally {
        setIsLoadingCompletedTasks(false);
      }
    };

    if (showCompletedTasks) {
      fetchCompletedTasks();
    }
  }, [jwtToken, showCompletedTasks]);

  useEffect(() => {
    const loadCurrentTask = async () => {
      try {
        const response = await fetch(TimerAPIUrls.CURRENT_TASK, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        });

        if (response.ok) {
          const data = await response.json();
          const taskWithSeconds = {
            taskId: data.taskId.toString(),
            taskName: data.taskName,
            timeLeft: data.timeLeft,
            duration: data.duration,
          };
          setCurrentTask(taskWithSeconds);
          setEstimatedFinishTime(
            addMinutes(new Date(), Math.ceil(data.timeLeft))
          );
        }
      } catch (error) {
        console.error("Error loading current task:", error);
      }
    };

    loadCurrentTask();
  }, [jwtToken]);

  const handleSaveBackground = async () => {
    try {
      let backgroundValue = tempBackground.value;

      // Ensure muted preference is included for YouTube backgrounds
      // If muted state is null for YouTube, default to false
      const muted =
        tempBackground.type === "youtube"
          ? isVideoMuted ?? false // Use null coalescing to default to false
          : null;

      const backgroundData = {
        backgroundType: getApiPreferenceType(tempBackground.type),
        backgroundValue:
          tempBackground.type === "color" && !backgroundValue.startsWith("#")
            ? `#${backgroundValue}`
            : backgroundValue,
        muted,
      };

      const response = await fetch(TimerAPIUrls.BACKGROUND, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(backgroundData),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || "Failed to save background");
      }

      const newBackground: Background = {
        type: tempBackground.type,
        value: backgroundValue,
        isMuted: tempBackground.type === "youtube" ? isVideoMuted : undefined,
      };

      setBackground(newBackground);
      setSelectedImagePreview(null);
      setIsSidebarOpen(false);

      if (tempBackground.type === "image") {
        const { width, height } = await getImageDimensions(backgroundValue);
        setBackgroundStyle({
          ...calculateBackgroundStyle(
            width,
            height,
            window.innerWidth,
            window.innerHeight
          ),
          backgroundImage: `url(${backgroundValue})`,
          backgroundSize: "cover",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: "100vw",
          height: "100vh",
        });
      }
    } catch (error) {
      console.error("Error saving background:", error);
    }
  };

  const handleAddTask = async () => {
    setInputError("");

    if (!newTask.name.trim()) {
      setInputError("Task name is required");
      return;
    }
    if (!newTask.duration) {
      setInputError("Duration is required");
      return;
    }
    if (newTask.duration <= 0) {
      setInputError("Duration must be greater than 0");
      return;
    }

    setIsAddingTask(true);
    try {
      await fetch(TimerAPIUrls.TASKS, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({
          name: newTask.name,
          duration: newTask.duration,
        }),
      });

      const response = await fetch(TimerAPIUrls.ACTIVE_TASKS, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      const data = await response.json();
      setTasks(data);
      setNewTask({ name: "", duration: 0 });
      setInputError("");
    } catch (error) {
      console.error("Error adding task:", error);
      setInputError("Failed to add task");
    } finally {
      setIsAddingTask(false);
    }
  };

  const handleStartTask = async (task: Task) => {
    if (currentTask) {
      setTaskToSwitchTo(task);
      setShowSwitchTaskModal(true);
      return;
    }

    await startTask(task);
  };

  const startTask = async (task: Task) => {
    setIsStartingTask(task.taskId);
    try {
      const response = await fetch(TimerAPIUrls.SET_CURRENT_TASK(task.taskId), {
        method: "POST",
        headers: { Authorization: `Bearer ${jwtToken}` },
      });

      if (!response.ok) {
        if (response.status === 409) {
          setInputError("You already have an active task.");
          return;
        }
        throw new Error("Failed to set current task");
      }

      const taskWithMinutes = {
        ...task,
        timeLeft: task.timeLeft, // Keep as decimal minutes
      };
      setCurrentTask(taskWithMinutes);
      setIsRunning(true);
      setEstimatedFinishTime(addMinutes(new Date(), Math.ceil(task.timeLeft)));
      setIsTasksSidebarOpen(false);
      setTasks(tasks.filter((t) => t.taskId !== task.taskId));
    } catch (error) {
      console.error("Error starting task:", error);
      setInputError("Failed to start task");
    } finally {
      setIsStartingTask(null);
    }
  };

  const handleSwitchTask = async () => {
    if (!taskToSwitchTo || !currentTask) return;

    try {
      const timeLeftMinutes = currentTask.timeLeft ?? 0;
      const totalMinutes = currentTask.duration;
      const minutesDone = totalMinutes - timeLeftMinutes;

      await fetch(TimerAPIUrls.EXIT_TASK(currentTask.taskId), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: new URLSearchParams({
          timeDone: minutesDone.toFixed(2), // Send exact decimal minutes
        }),
      });

      setCurrentTask(null);
      localStorage.removeItem("currentTask");

      await startTask(taskToSwitchTo);

      // After starting new task, fetch fresh task list
      const response = await fetch(TimerAPIUrls.ACTIVE_TASKS, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      const data = await response.json();
      setTasks(data);
    } catch (error) {
      console.error("Error switching tasks:", error);
      setInputError("Failed to switch tasks");
    } finally {
      setShowSwitchTaskModal(false);
      setTaskToSwitchTo(null);
    }
  };

  const handlePause = async () => {
    if (!currentTask) return;
  
    try {
      const timeLeftMinutes = currentTask.timeLeft ?? 0;
      const totalMinutes = currentTask.duration;
      const minutesDone = totalMinutes - timeLeftMinutes;
  
      const response = await fetch(TimerAPIUrls.TASK(currentTask.taskId), {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: new URLSearchParams({
          timeDone: minutesDone.toFixed(2),
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to update task progress");
      }
  
      setIsRunning(false);
      // Store the exact time left when pausing
      localStorage.setItem('currentTask', JSON.stringify({
        ...currentTask,
        timeLeft: timeLeftMinutes
      }));
    } catch (error) {
      console.error("Error saving progress:", error);
      setIsRunning(false);
    }
  };
  
  const handleEndTask = async () => {
    if (!currentTask) return;

    try {
      await fetch(TimerAPIUrls.COMPLETE_TASK(currentTask.taskId), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      const completedResponse = await fetch(TimerAPIUrls.COMPLETED_TASKS, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      const completedData = await completedResponse.json();
      setCompletedTasks(completedData);

      const activeResponse = await fetch(TimerAPIUrls.ACTIVE_TASKS, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      const activeData = await activeResponse.json();
      setTasks(activeData);

      setCurrentTask(null);
      setIsRunning(false);
      setEstimatedFinishTime(null);
      localStorage.removeItem("currentTask");
      document.title = "Revise Wizard - Timer";
    } catch (error) {
      console.error("Error completing task:", error);
    }
  };

  const handleBackgroundChange = async (
    type: "color" | "image" | "youtube",
    value: string
  ) => {
    let newBackground: Background = {
      type,
      value,
      ...(type === "youtube" ? { isMuted: newVideoMuted } : {}),
    };

    if (type === "youtube") {
      const videoId = validateYoutubeUrl(value);
      if (!videoId) return;
      // Add enablejsapi=1 to allow JavaScript control
      const embedUrl = `${videoId}?autoplay=1&controls=0&showinfo=0&rel=0&loop=1&enablejsapi=1`;
      newBackground.value = embedUrl;
    }

    setTempBackground(newBackground);
    setSelectedBackgroundType(type);
    if (type === "image") {
      setSelectedImagePreview(value);
      try {
        const { width, height } = await getImageDimensions(value);
        const style = calculateBackgroundStyle(
          width,
          height,
          window.innerWidth,
          window.innerHeight
        );
        setBackgroundStyle({
          ...style,
          backgroundImage: `url(${value})`,
          backgroundSize: "cover",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: "100vw",
          height: "100vh",
        });
      } catch (error) {
        console.error("Error calculating background style:", error);
      }
    }
  };

  useEffect(() => {
    const savedTask = localStorage.getItem("currentTask");
    if (savedTask) {
      const parsedTask = JSON.parse(savedTask);
      setCurrentTask(parsedTask);
      if (parsedTask.timeLeft > 0) {
        setIsRunning(false);
        setEstimatedFinishTime(
          addMinutes(new Date(), Math.ceil(parsedTask.timeLeft / 60))
        );
      }
    }
  }, []);

  useEffect(() => {
    if (currentTask) {
      localStorage.setItem("currentTask", JSON.stringify(currentTask));
    } else {
      localStorage.removeItem("currentTask");
    }
  }, [currentTask]);

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch(TimerAPIUrls.ACTIVE_TASKS, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        });
        const data = await response.json();

        const savedTaskString = localStorage.getItem("currentTask");
        if (savedTaskString) {
          const savedTask = JSON.parse(savedTaskString);
          setTasks(
            data.filter((task: Task) => task.taskId !== savedTask.taskId)
          );
        } else {
          setTasks(data);
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
  }, [jwtToken]);

  const formatTime = (timeInMinutes: number): string => {
    const totalSeconds = Math.round(timeInMinutes * 60);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleMuteToggle = async () => {
    const newMutedState = !isVideoMuted;

    try {
      if (background.type === "youtube" && youtubeIframe) {
        // Send mute/unmute command directly to the iframe
        youtubeIframe.contentWindow?.postMessage(
          JSON.stringify({
            event: "command",
            func: newMutedState ? "mute" : "unMute",
            args: [],
          }),
          "*"
        );
      }

      const response = await fetch(TimerAPIUrls.BACKGROUND_MUTE, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: new URLSearchParams({
          muted: String(newMutedState),
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update mute preference");
      }

      // Only update state after successful API call
      setIsVideoMuted(newMutedState);
      setBackground((prev) => ({
        ...prev,
        isMuted: newMutedState,
      }));
      setTempBackground((prev) => ({
        ...prev,
        isMuted: newMutedState,
      }));
    } catch (error) {
      console.error("Error updating mute preference:", error);
      // Show error to user
      setYoutubeError("Failed to update mute preference");
    }
  };

  const handleDeleteTask = async (
    taskId: string,
    isCompleted: boolean = false
  ) => {
    try {
      const numericId = parseInt(taskId);
      if (isNaN(numericId)) {
        console.error("Invalid task ID format");
        return;
      }

      const response = await fetch(TimerAPIUrls.TASK(numericId.toString()), {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (response.ok) {
        if (isCompleted) {
          setCompletedTasks(completedTasks.filter((t) => t.taskId !== taskId));
        } else {
          setTasks(tasks.filter((t) => t.taskId !== taskId));
        }
      } else {
        const errorData = await response.json();
        console.error("Failed to delete task:", errorData);
      }
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      setSelectedBackgroundType(background.type);
      setTempBackground(background);
      if (background.type === "youtube") {
        setIsVideoMuted(background.isMuted!!);
      }
    }
  }, [isSidebarOpen, background]);

  const validateYoutubeUrl = (url: string): string | null => {
    setYoutubeError("");

    const patterns = [
      /^[a-zA-Z0-9_-]{11}$/, // Direct video ID
      /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/,
    ];

    let videoId = null;

    if (patterns[0].test(url)) {
      videoId = url;
    } else {
      for (const pattern of patterns) {
        const match = url.match(pattern);
        if (match && match[1]) {
          videoId = match[1];
          break;
        }
      }
    }

    if (!videoId) {
      setYoutubeError("Invalid YouTube URL or video ID");
      return null;
    }

    // Return base URL without mute parameter
    return `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&showinfo=0&rel=0&loop=1&playlist=${videoId}&enablejsapi=1`;
  };

  const getApiPreferenceType = (
    type: "color" | "image" | "youtube"
  ): string => {
    return type.toLowerCase();
  };

  const [isInitialLoading, setIsInitialLoading] = useState(true);

  useEffect(() => {
    const loadBackgroundPreference = async () => {
      setIsInitialLoading(true);
      try {
        const response = await fetch(TimerAPIUrls.BACKGROUND, {
          headers: { Authorization: `Bearer ${jwtToken}` },
        });

        if (!response.ok || response.status === 204) {
          const defaultBackground = {
            type: "image" as const,
            value: "/backgrounds/main.jpg",
          };
          setBackground(defaultBackground);
          setTempBackground(defaultBackground);
          const { width, height } = await getImageDimensions(
            defaultBackground.value
          );
          const style = calculateBackgroundStyle(
            width,
            height,
            window.innerWidth,
            window.innerHeight
          );
          setBackgroundStyle({
            ...style,
            backgroundImage: `url(${defaultBackground.value})`,
            backgroundSize: "cover",
          });
          setIsInitialLoading(false);
          return;
        }

        const data = await response.json();
        console.log("Loaded background from API:", data);

        let newValue = data.value;
        // Add mute parameter to YouTube URL if isMuted is true
        if (data.preferenceType.toLowerCase() === "youtube" && data.muted) {
          const separator = data.value.includes("?") ? "&" : "?";
          newValue = `${data.value}${separator}mute=1`;
        }

        const newBackground = {
          type: data.preferenceType.toLowerCase() as
            | "color"
            | "image"
            | "youtube",
          value: newValue,
          isMuted: data.muted,
        };

        setBackground(newBackground);
        setTempBackground(newBackground);
        setIsVideoMuted(data.muted);

        if (newBackground.type === "image") {
          const { width, height } = await getImageDimensions(
            newBackground.value
          );
          const style = calculateBackgroundStyle(
            width,
            height,
            window.innerWidth,
            window.innerHeight
          );
          setBackgroundStyle({
            ...style,
            backgroundImage: `url(${newBackground.value})`,
            backgroundSize: "cover",
          });
        } else if (newBackground.type === "color") {
          setBackgroundStyle({
            backgroundColor: newBackground.value,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100vw",
            height: "100vh",
          });
        }
      } catch (error) {
        console.error("Error loading background from API:", error);
        const defaultBackground = {
          type: "image" as const,
          value: "/backgrounds/main.jpg",
        };
        setBackground(defaultBackground);
        setTempBackground(defaultBackground);
      } finally {
        setIsInitialLoading(false);
      }
    };

    loadBackgroundPreference();
  }, [jwtToken]);

  // Replace handleDeleteCurrentTask with handleExitCurrentTask
  const handleExitCurrentTask = async () => {
    if (!currentTask) return;

    try {
      const timeLeftMinutes = currentTask.timeLeft ?? 0;
      const totalMinutes = currentTask.duration;
      const minutesDone = totalMinutes - timeLeftMinutes;

      await fetch(TimerAPIUrls.EXIT_TASK(currentTask.taskId), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: new URLSearchParams({
          timeDone: minutesDone.toFixed(2), // Send exact decimal minutes
        }),
      });

      // Fetch fresh task list instead of manually adding to prevent duplicates
      const response = await fetch(TimerAPIUrls.ACTIVE_TASKS, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      const data = await response.json();
      setTasks(data);

      // Clear current task
      setCurrentTask(null);
      setIsRunning(false);
      setEstimatedFinishTime(null);
      localStorage.removeItem("currentTask");
      document.title = "Revise Wizard - Timer"; // Reset title when exiting task
    } catch (error) {
      console.error("Error exiting task:", error);
    }
  };

  useEffect(() => {
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);
  }, []);

  useEffect(() => {
    if (background.type === 'youtube' && isYoutubeReady) {
        // Initial play attempts
        const attempts = [0, 500, 1000, 2000]; // Try multiple times
        const timers = attempts.map(delay => 
            setTimeout(() => handleForcePlay(), delay)
        );

        const handleYouTubeMessage = (event: MessageEvent) => {
            try {
                // Handle both string and object messages
                const data = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
                
                // React to any indication that video is not playing
                if (
                    (data.event === 'onStateChange' && data.info === 2) || // Paused
                    (data.event === 'onStateChange' && data.info === 0) || // Ended
                    (data.event === 'onStateChange' && data.info === -1) || // Unstarted
                    data.event === 'onError'
                ) {
                    handleForcePlay();
                }
            } catch (e) {
                // If parsing fails, try force play anyway
                handleForcePlay();
            }
        };

        window.addEventListener('message', handleYouTubeMessage);
        
        // Also try to play when tab becomes visible
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                handleForcePlay();
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            timers.forEach(timer => clearTimeout(timer));
            window.removeEventListener('message', handleYouTubeMessage);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }
}, [background.type, isYoutubeReady, handleForcePlay]);

  useEffect(() => {
    // Define the callback for when YouTube API is ready
    window.onYouTubeIframeAPIReady = () => {
      setIsYoutubeReady(true);
    };

    // Load YouTube API script
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

    return () => {
      window.onYouTubeIframeAPIReady = undefined;
    };
  }, []);

  const handleStartResume = () => {
    if (isRunning) {
      handlePause();
    } else {
      if (!currentTask || currentTask.duration === undefined) return;

      // Reset initial time reference when starting/resuming
      initialTimeRef.current = Date.now();
      setIsRunning(true);
      
      // Calculate finish time based on the exact remaining time
      const finishTime = addMinutes(new Date(), currentTask.timeLeft);
      setEstimatedFinishTime(finishTime);
    }
  };

  // Add this function back
  const handleDeleteCurrentTask = async () => {
    if (!currentTask) return;

    try {
      const response = await fetch(TimerAPIUrls.TASK(currentTask.taskId), {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (response.ok) {
        setCurrentTask(null);
        setIsRunning(false);
        setEstimatedFinishTime(null);
        localStorage.removeItem("currentTask");
      } else {
        console.error("Failed to delete current task");
      }
    } catch (error) {
      console.error("Error deleting current task:", error);
    }
  };

  const formatRemainingTime = (timeLeft: number): string => {
    if (timeLeft === 0) return "0m";

    const hours = Math.floor(timeLeft / 60);
    const minutes = Math.floor(timeLeft % 60);
    const seconds = Math.round((timeLeft % 1) * 60);

    let timeString = "";
    if (hours > 0) {
      timeString += `${hours}h `;
    }
    if (minutes > 0 || hours > 0) {
      timeString += `${minutes}m`;
    }
    if (seconds > 0) {
      timeString += ` ${seconds}s`;
    }
    return timeString;
  };

  const formatCompletedTime = (duration: number): string => {
    if (duration === 0) return "Completed (0m)";

    const totalMinutes = Math.floor(duration);
    const seconds = Math.round((duration % 1) * 60);

    if (seconds === 0) {
      return `Completed (${totalMinutes}m)`;
    }
    return `Completed (${totalMinutes}m ${seconds}s)`;
  };

  const formatTitleTime = (timeInMinutes: number): string => {
    if (timeInMinutes === 0) return "0:00:00";

    const totalSeconds = Math.round(timeInMinutes * 60);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (hours > 0) {
      return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  // Replace the timer effect with this updated version
  useEffect(() => {
    if (isRunning && currentTask) {
        const startTime = Date.now();
        const startMinutes = currentTask.timeLeft;
        lastUpdateTimeRef.current = startTime;

        const updateTime = () => {
            const now = Date.now();
            const elapsedMinutes = (now - startTime) / (1000 * 60);
            const newTimeLeft = Math.max(0, startMinutes - elapsedMinutes);

            setCurrentTask(prev => {
                if (!prev) return prev;
                const updatedTask = { ...prev, timeLeft: newTimeLeft };
                localStorage.setItem('currentTask', JSON.stringify(updatedTask));
                document.title = `(${formatTitleTime(newTimeLeft)}) ${prev.taskName} - Revise Wizard`;

                // Update estimated finish time with exact minutes
                const finishTime = addMinutes(new Date(), newTimeLeft);
                setEstimatedFinishTime(finishTime);

                if (newTimeLeft === 0) {
                    setIsRunning(false);
                }

                return updatedTask;
            });
        };

        const interval = setInterval(() => {
            if (!document.hidden) {
                updateTime();
            }
        }, 1000);

        // Handle tab visibility changes
        const handleVisibilityChange = () => {
            if (!document.hidden) {
                updateTime();
            }
            lastUpdateTimeRef.current = Date.now();
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            clearInterval(interval);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            document.title = 'Revise Wizard - Timer';
        };
    }
}, [isRunning, currentTask]);

  return (
    <div className="h-screen overflow-hidden">
      <Helmet>
        <title>Revise Wizard - Timer</title>
        <meta
          name="description"
          content="Revision Timer - Stay focused and track your study sessions"
        />
        <link rel="canonical" href={getWebsiteUrl() + "/timer"} />
        <style>
          {`
                        body {
                            overflow: hidden;
                            position: fixed;
                            width: 100%;
                            height: 100%;
                        }
                    `}
        </style>
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      {isInitialLoading && (
        <div className="fixed inset-0 z-[300] flex flex-col items-center justify-center bg-gradient-to-b from-gray-900 to-gray-800">
          <div className="text-center">
            <h1 className="mb-8 text-3xl font-bold text-white">
              Building Revision Timer
              <span className="animate-pulse">...</span>
            </h1>
            <div className="w-64 h-2 mx-auto mb-4 overflow-hidden bg-gray-700 rounded-full">
              <div className="h-full transition-all duration-300 bg-gradient-to-r from-blue-500 to-purple-500 animate-loadingBar" />
            </div>
            <p className="text-gray-400">Please wait a moment</p>
          </div>
        </div>
      )}

      <div
        className="fixed inset-0 min-h-screen overflow-hidden bg-gradient-to-b from-black/20 to-black/40"
        style={{
          ...backgroundStyle,
          ...(background.type === "color"
            ? { backgroundColor: background.value }
            : {}),
          backgroundImage:
            background.type === "image" ? `url(${background.value})` : "none",
        }}
      />

      <div className="relative z-10 flex flex-col min-h-screen overflow-hidden">
        {background.type === "youtube" && (
          <iframe
            ref={(ref) => setYoutubeIframe(ref)}
            className="fixed top-0 left-0 z-0 w-screen h-screen pointer-events-none"
            src={`${background.value}&enablejsapi=1&playsinline=1&mute=${
              background.isMuted ? "1" : "0"
            }`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              width: "177.77777778vh" /* 16:9 aspect ratio */,
              height: "56.25vw" /* 16:9 aspect ratio */,
              minWidth: "100%",
              minHeight: "100%",
              transform: "translate(-50%, -50%)",
              border: "none",
              objectFit: "cover",
              zIndex: 0,
            }}
            id="youtube-player"
            title="background"
            frameBorder="0"
          />
        )}

        <div className="fixed z-[200] bottom-8 sm:bottom-12 left-1/2 transform -translate-x-1/2 flex gap-6">
          <div className="flex flex-col items-center">
            <button
              onClick={() => setIsSidebarOpen(true)}
              className="p-4 mb-2 transition-all border border-white/20 rounded-2xl bg-black/40 backdrop-blur-xl hover:bg-black/50 hover:scale-105 hover:shadow-xl"
              style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}
            >
              <svg
                className="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            </button>
            <span className="text-sm font-medium text-white/90 backdrop-blur-sm">
              Settings
            </span>
          </div>

          <div className="flex flex-col items-center">
            <button
              onClick={() => setIsTasksSidebarOpen(true)}
              className="p-4 mb-2 transition-all border border-white/20 rounded-2xl bg-black/40 backdrop-blur-xl hover:bg-black/50 hover:scale-105 hover:shadow-xl"
              style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}
            >
              <svg
                className="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                />
              </svg>
            </button>
            <span className="text-sm font-medium text-white/90 backdrop-blur-sm">
              Tasks
            </span>
          </div>

          <div className="flex flex-col items-center">
            <button
              onClick={() => {
                setIsCompletedTasksSidebarOpen(true);
                setShowCompletedTasks(true);
              }}
              className="p-4 mb-2 transition-all border border-white/20 rounded-2xl bg-black/40 backdrop-blur-xl hover:bg-black/50 hover:scale-105 hover:shadow-xl"
              style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}
            >
              <svg
                className="w-6 h-6 text-white"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2M9 12l2 2 4-4"
                />
              </svg>
            </button>
            <span className="text-sm font-medium text-white/90 backdrop-blur-sm">
              Completed
            </span>
          </div>

          {background.type === "youtube" && (
            <div className="flex flex-col items-center">
              <button
                onClick={handleForcePlay}
                className="p-4 mb-2 transition-all border border-white/20 rounded-2xl bg-black/40 backdrop-blur-xl hover:bg-black/50 hover:scale-105 hover:shadow-xl"
                style={{ boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}
                title="Force Play Video"
              >
                <svg
                  className="w-6 h-6 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </button>
              <span className="text-sm font-medium text-white/90 backdrop-blur-sm">
                Play Video
              </span>
            </div>
          )}
        </div>

        {currentTask && (
          <div className="fixed inset-0 z-40 flex flex-col items-center justify-center pb-32 sm:pb-40">
            <div className="w-full max-w-lg px-4 mx-auto">
              <div
                className="w-full p-8 mb-6 text-center transition-all duration-300 border border-white/10 rounded-3xl bg-black/40 backdrop-blur-xl hover:backdrop-blur-2xl"
                style={{
                  boxShadow:
                    "0 8px 32px rgba(0, 0, 0, 0.2), 0 2px 8px rgba(255, 255, 255, 0.05)",
                }}
              >
                <div className="flex justify-end gap-2 mb-2">
                  <button
                    onClick={handleExitCurrentTask}
                    className="p-2 text-white transition-all rounded-xl bg-gradient-to-br from-amber-400/70 to-amber-600/70 hover:from-amber-400/90 hover:to-amber-600/90 hover:scale-105 active:scale-95"
                    title="Exit current task"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={handleDeleteCurrentTask}
                    className="p-2 text-white transition-all rounded-xl bg-gradient-to-br from-red-400/70 to-red-600/70 hover:from-red-400/90 hover:to-red-600/90 hover:scale-105 active:scale-95"
                    title="Delete current task"
                  >
                    <FaTrash className="w-5 h-5" />
                  </button>
                </div>

                <h2 className="mb-6 text-4xl font-bold tracking-tight text-transparent bg-gradient-to-r from-blue-200 to-blue-100 bg-clip-text font-inter">
                  {currentTask.taskName}
                </h2>

                <div
                  className={`mb-8 text-8xl font-bold tracking-tighter transition-all duration-300 transform font-inter text-transparent bg-gradient-to-r from-white to-white/80 bg-clip-text
                                    ${isRunning ? "scale-105" : "scale-100"}`}
                >
                  <span className="inline-block transition-all duration-300">
                    {formatTime(currentTask.timeLeft ?? 0)}
                  </span>
                </div>

                {estimatedFinishTime && (
                  <div className="text-xl font-medium text-white/80 font-inter">
                    Finish at {format(estimatedFinishTime, "HH:mm")}
                  </div>
                )}
              </div>

              <div className="flex justify-between w-full gap-4">
                <button
                  onClick={handleStartResume}
                  className={`w-1/2 px-8 py-3 text-lg font-medium transition-all rounded-xl ${
                    isDyslexiaMode
                      ? isRunning
                        ? "bg-gray-700 hover:bg-gray-600 text-white"
                        : "bg-blue-900 hover:bg-blue-800 text-white"
                      : isRunning
                      ? "bg-gradient-to-r from-amber-500 to-amber-600 hover:from-amber-600 hover:to-amber-700 text-white"
                      : "bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white"
                  } transform hover:scale-105 active:scale-95`}
                >
                  {isRunning ? "Pause" : "Resume"}
                </button>
                <button
                  onClick={handleEndTask}
                  className={`w-1/2 px-8 py-3 text-lg font-medium transition-all transform rounded-xl ${
                    isDyslexiaMode
                      ? "bg-rose-900 hover:bg-rose-800 text-white"
                      : "bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700"
                  } text-white hover:scale-105 active:scale-95`}
                >
                  End Task
                </button>
              </div>
            </div>
          </div>
        )}

        <div
          className={`fixed top-[64px] right-0 h-[calc(100vh-64px)] w-full md:w-80 bg-white/95 dark:bg-gray-800/95 
                               transform transition-transform duration-300 ease-in-out z-[201]
                               ${
                                 isSidebarOpen
                                   ? "translate-x-0"
                                   : "translate-x-full"
                               }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="h-full p-6 overflow-y-auto">
            <div className="flex items-center">
              <button
                onClick={() => setIsSidebarOpen(false)}
                className="p-2 text-gray-800 transition-colors rounded-full dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="mt-4 text-gray-800 dark:text-white">
              <h2 className="mb-4 text-2xl font-bold">Background</h2>
              <div className="space-y-4">
                <div className="grid grid-cols-3 gap-2">
                  <button
                    onClick={() => setSelectedBackgroundType("color")}
                    className={`px-4 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${
                      selectedBackgroundType === "color"
                        ? "bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-lg transform scale-105"
                        : "bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"
                    }`}
                  >
                    Color
                  </button>
                  <button
                    onClick={() => setSelectedBackgroundType("image")}
                    className={`px-4 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${
                      selectedBackgroundType === "image"
                        ? "bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-lg transform scale-105"
                        : "bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"
                    }`}
                  >
                    Image
                  </button>
                  <button
                    onClick={() => setSelectedBackgroundType("youtube")}
                    className={`px-4 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${
                      selectedBackgroundType === "youtube"
                        ? "bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-lg transform scale-105"
                        : "bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600"
                    }`}
                  >
                    YT
                  </button>
                </div>

                {selectedBackgroundType === "color" && (
                  <div className="p-4 rounded-lg bg-gray-50 dark:bg-gray-700/50 backdrop-blur-sm">
                    <div className="flex items-center gap-4">
                      <input
                        type="color"
                        value={
                          tempBackground.type === "color"
                            ? tempBackground.value
                            : "#ffffff"
                        }
                        onChange={(e) =>
                          handleBackgroundChange("color", e.target.value)
                        }
                        className="w-16 h-16 bg-transparent border-0 rounded-lg cursor-pointer"
                      />
                      <span className="text-sm opacity-75">
                        Pick a color for your background
                      </span>
                    </div>
                  </div>
                )}

                {selectedBackgroundType === "image" && (
                  <div className="space-y-4">
                    {isLoadingImages ? (
                      <div className="flex items-center justify-center h-32 rounded-lg bg-gray-50 dark:bg-gray-700/50">
                        <span className="text-sm opacity-75">
                          Loading images...
                        </span>
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 gap-4">
                        {imageOptions.map((image, index) => (
                          <div
                            key={index}
                            className={`relative h-32 overflow-hidden rounded-lg shadow-lg transition-all duration-200 ${
                              selectedImagePreview === image
                                ? "ring-4 ring-blue-500 ring-offset-2 dark:ring-offset-gray-800 transform scale-[1.02]"
                                : "hover:ring-2 hover:ring-blue-400 hover:ring-offset-2 dark:hover:ring-offset-gray-800"
                            }`}
                          >
                            <img
                              src={image}
                              alt={`Background ${index + 1}`}
                              className="object-cover w-full h-full transition-transform duration-200 cursor-pointer"
                              onClick={() =>
                                handleBackgroundChange("image", image)
                              }
                              onError={(e) => {
                                console.error(`Failed to load image: ${image}`);
                                e.currentTarget.style.display = "none";
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}

                {selectedBackgroundType === "youtube" && (
                  <div className="p-4 space-y-4 rounded-lg bg-gray-50 dark:bg-gray-700/50 backdrop-blur-sm">
                    {/* Current Video Section */}
                    {background.type === "youtube" && (
                      <div className="mb-6">
                        <h3 className="mb-2 text-sm font-semibold text-gray-700 dark:text-gray-300">
                          Current Background
                        </h3>
                        <div className="p-4 space-y-3 border rounded-lg border-gray-200/50 dark:border-gray-600/50 bg-white/50 dark:bg-gray-800/50">
                          <div className="flex flex-col gap-2">
                            <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
                              {background.value.includes("youtube.com/embed/")
                                ? `Video ID: ${
                                    background.value
                                      .split("/embed/")[1]
                                      .split("?")[0]
                                  }`
                                : "No video playing"}
                            </span>
                            <div className="flex items-center justify-between">
                              <span className="text-sm text-gray-600 dark:text-gray-400">
                                Audio
                              </span>
                              <label className="relative inline-flex items-center cursor-pointer">
                                <input
                                  type="checkbox"
                                  checked={isVideoMuted}
                                  onChange={handleMuteToggle}
                                  className="sr-only peer"
                                />
                                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                <span className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                                  {isVideoMuted ? "Muted" : "Unmuted"}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* New Video Section */}
                    <div className="space-y-3">
                      <h3 className="text-sm font-semibold text-gray-700 dark:text-gray-300">
                        Set New Background Video
                      </h3>
                      <div className="space-y-4">
                        <input
                          type="text"
                          placeholder="Enter YouTube video ID or URL"
                          className="w-full p-3 text-sm border-0 rounded-lg shadow-sm bg-white/80 dark:bg-gray-600/50 focus:ring-2 focus:ring-blue-500"
                          onChange={(e) =>
                            handleBackgroundChange("youtube", e.target.value)
                          }
                        />
                        {youtubeError && (
                          <p className="mt-1 text-xs text-red-500">
                            {youtubeError}
                          </p>
                        )}

                        <div className="p-3 text-xs text-gray-500 rounded-lg bg-gray-100/50 dark:bg-gray-600/30 dark:text-gray-400">
                          <strong className="block mb-1">
                            Accepted formats:
                          </strong>
                          <ul className="space-y-1 list-disc list-inside">
                            <li>Full URL (youtube.com/watch?v=...)</li>
                            <li>Short URL (youtu.be/...)</li>
                            <li>Video ID (dQw4w9WgXcQ)</li>
                          </ul>
                        </div>

                        <div className="flex flex-col gap-2">
                          <span className="text-sm text-gray-600 dark:text-gray-400">
                            audio
                          </span>
                          <label className="relative inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              checked={newVideoMuted}
                              onChange={(e) =>
                                setNewVideoMuted(e.target.checked)
                              }
                              className="sr-only peer"
                            />
                            <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            <span className="ml-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                              {newVideoMuted ? "Unmuted" : "Muted"}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <button
                  onClick={handleSaveBackground}
                  disabled={isSaveButtonDisabled()}
                  className={`w-full px-6 py-3 rounded-lg font-medium transition-all duration-200 ${
                    isSaveButtonDisabled()
                      ? "bg-gray-300 dark:bg-gray-600 cursor-not-allowed opacity-50"
                      : "bg-gradient-to-r from-blue-500 to-blue-600 text-white shadow-lg hover:shadow-xl hover:scale-[1.02]"
                  }`}
                >
                  Save Background
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`fixed top-[64px] right-0 h-[calc(100vh-64px)] w-full md:w-80 bg-white/95 dark:bg-gray-800/95 
                               transform transition-transform duration-300 ease-in-out z-[201]
                               ${
                                 isTasksSidebarOpen
                                   ? "translate-x-0"
                                   : "translate-x-full"
                               }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="h-full p-6 overflow-y-auto">
            <div className="flex items-center">
              <button
                onClick={() => setIsTasksSidebarOpen(false)}
                className="p-2 text-gray-800 transition-colors rounded-full dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="mt-4 text-gray-800 dark:text-white">
              <div className="mt-8 mb-8">
                <h2 className="mb-4 text-2xl font-bold">Create Task</h2>
                <input
                  type="text"
                  placeholder="Task name"
                  value={newTask.name}
                  onChange={(e) =>
                    setNewTask({ ...newTask, name: e.target.value })
                  }
                  className="w-full p-2 mb-4 border rounded dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  required
                />
                <input
                  type="number"
                  placeholder="Duration (minutes)"
                  value={newTask.duration || ""}
                  onChange={(e) =>
                    setNewTask({
                      ...newTask,
                      duration: parseInt(e.target.value) || 0,
                    })
                  }
                  className="w-full p-2 mb-4 border rounded dark:bg-gray-700 dark:text-white dark:border-gray-600"
                  min="1"
                  required
                />
                {inputError && (
                  <div className="p-2 mb-4 text-sm text-red-600 bg-red-100 rounded dark:bg-red-900/30 dark:text-red-400">
                    {inputError}
                  </div>
                )}
                <button
                  onClick={handleAddTask}
                  disabled={
                    !newTask.name.trim() ||
                    !newTask.duration ||
                    newTask.duration <= 0 ||
                    isAddingTask
                  }
                  className={`w-full px-4 py-2 text-white rounded ${
                    !newTask.name.trim() ||
                    !newTask.duration ||
                    newTask.duration <= 0 ||
                    isAddingTask
                      ? "bg-gray-400 cursor-not-allowed"
                      : ""
                  }`}
                  style={
                    !newTask.name.trim() ||
                    !newTask.duration ||
                    newTask.duration <= 0 ||
                    isAddingTask
                      ? {}
                      : getCurrentButtonStyles(isDarkMode)
                  }
                >
                  {isAddingTask ? (
                    <div className="flex items-center justify-center">
                      <div className="w-5 h-5 mr-2 border-2 border-white rounded-full border-t-transparent animate-spin"></div>
                      Adding...
                    </div>
                  ) : (
                    "Add Task"
                  )}
                </button>
              </div>

              {tasks.length > 0 && (
                <div className="mb-8">
                  <h2 className="mb-4 text-2xl font-bold">Your Tasks</h2>
                  <div className="space-y-3">
                    {tasks.map((task) => (
                      <div
                        key={task.taskId}
                        className="p-4 transition-all duration-200 rounded-lg bg-white/10 backdrop-blur-sm hover:bg-white/20 dark:bg-gray-700/50 dark:hover:bg-gray-700/70"
                      >
                        <div className="flex flex-col">
                          <span className="mb-1 text-lg font-semibold">
                            {task.taskName}
                          </span>
                          <span className="mb-3 text-sm text-gray-600 dark:text-gray-400">
                            {formatRemainingTime(task.timeLeft)}
                          </span>
                          <div className="flex justify-end gap-2">
                            <button
                              onClick={() => handleStartTask(task)}
                              disabled={isStartingTask === task.taskId}
                              className="px-4 py-2 text-sm font-medium text-white transition-all rounded-md bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700"
                            >
                              {isStartingTask === task.taskId ? (
                                <div className="flex items-center">
                                  <div className="w-4 h-4 mr-2 border-2 border-white rounded-full border-t-transparent animate-spin"></div>
                                  Starting...
                                </div>
                              ) : (
                                "Start"
                              )}
                            </button>
                            <button
                              onClick={() => handleDeleteTask(task.taskId)}
                              className="p-2 text-white transition-all rounded-md bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700"
                              title="Delete task"
                            >
                              <FaTrash className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className={`fixed top-[64px] right-0 h-[calc(100vh-64px)] w-full md:w-80 bg-white/95 dark:bg-gray-800/95 
                               transform transition-transform duration-300 ease-in-out z-[201]
                               ${
                                 isCompletedTasksSidebarOpen
                                   ? "translate-x-0"
                                   : "translate-x-full"
                               }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="h-full p-6 overflow-y-auto">
            <div className="flex items-center">
              <button
                onClick={() => setIsCompletedTasksSidebarOpen(false)}
                className="p-2 text-gray-800 transition-colors rounded-full dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700"
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="mt-4 text-gray-800 dark:text-white">
              <h2 className="mb-4 text-2xl font-bold">Completed Tasks</h2>
              <div className="space-y-3">
                {isLoadingCompletedTasks ? (
                  <div className="flex items-center justify-center py-8">
                    <div className="w-8 h-8 border-4 border-blue-500 rounded-full border-t-transparent animate-spin"></div>
                  </div>
                ) : completedTasks.length === 0 ? (
                  <p className="text-gray-500 dark:text-gray-400">
                    No completed tasks
                  </p>
                ) : (
                  completedTasks.map((task) => (
                    <div
                      key={task.taskId}
                      className="p-4 transition-all duration-200 rounded-lg bg-white/10 backdrop-blur-sm hover:bg-white/20 dark:bg-gray-700/50 dark:hover:bg-gray-700/70"
                    >
                      <div className="flex flex-col">
                        <span className="mb-1 text-lg font-semibold">
                          {task.taskName}
                        </span>
                        <span className="mb-3 text-sm text-gray-600 dark:text-gray-400">
                          {formatCompletedTime(task.duration)}
                        </span>
                        <div className="flex justify-end">
                          <button
                            onClick={() => handleDeleteTask(task.taskId, true)}
                            className="p-2 text-white transition-all rounded-md bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700"
                            title="Delete completed task"
                          >
                            <FaTrash className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>

        {(isSidebarOpen ||
          isTasksSidebarOpen ||
          isCompletedTasksSidebarOpen) && (
          <div
            className="fixed inset-0 bg-black/30 z-[200]"
            onClick={() => {
              setIsSidebarOpen(false);
              setIsTasksSidebarOpen(false);
              setIsCompletedTasksSidebarOpen(false);
            }}
            aria-label="Close settings"
          />
        )}

        {/* Add the switch task confirmation modal */}
        {showSwitchTaskModal && taskToSwitchTo && (
          <div className="fixed inset-0 z-[300] flex items-center justify-center bg-black/50">
            <div className="w-full max-w-md p-6 mx-4 bg-white rounded-lg shadow-xl dark:bg-gray-800">
              <h3 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
                Switch Task?
              </h3>
              <p className="mb-6 text-gray-600 dark:text-gray-300">
                Your current progress will be saved, and you can continue this
                task later. Do you want to switch to "{taskToSwitchTo.taskName}
                "?
              </p>
              <div className="flex justify-end gap-4">
                <button
                  onClick={() => {
                    setShowSwitchTaskModal(false);
                    setTaskToSwitchTo(null);
                  }}
                  className="px-4 py-2 text-gray-600 transition-colors rounded-md hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSwitchTask}
                  className="px-4 py-2 text-white transition-all rounded-md bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700"
                >
                  Switch Task
                </button>
              </div>{" "}
            </div>{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default RevisionTimer;
